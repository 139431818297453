import { getColor } from './utils'

export default {
  data() {
    return {
      page: 1,
      perPage: 10,
      total: 0,
      search: '',
      searchingMode: false,
      loading: false,
      lists: [],
      rows: [],
      next: null,
      prev: null,
      users: [],
      operators: [],
      status: ''
    };
  },
  watch: {
    perPage() {
      this.ticketList()
    },
    page() {
      this.ticketList()
    }
  },
  computed: {
    computedStatus () {
      return [
        { label: this.$tc("all", 2), value: '' },
        { label: this.$t("ticket.status_type.created"), value: 'created' },
        { label: this.$t("ticket.status_type.assigned"), value: 'assigned' },
        { label: this.$t("ticket.status_type.closed"), value: 'closed' }
      ]
    },
    headers() {
      return [
        {
          text: this.$t("user.email"),
          value: "email"
        },
        {
          text: this.$t("ticket.name"),
          value: "fname_lname"
        },
        {
          text: this.$tc("admin.operator",1),
          value: "fk_operator"
        },
        {
          text: this.$t("ticket.subject"),
          value: "asunto"
        },
        {
          text: this.$t("ticket.company"),
          value: "subdomain"
        },
        {
          text: this.$t("ticket.date"),
          value: "created_at"
        },
        {
          text: this.$t("ticket.status"),
          value: "status"
        },
        {
          text: this.$t("actions.options"),
          value: "options",
          align: 'center',
          width: 110
        }
      ]
    }
  },
  methods: {
    searchTicket(searchSold) {
      this.search = searchSold
      this.ticketList()
    },
    searchByStatus(){
      this.ticketList()
    },
    ticketList() {
      this.loading = true;
      this.axios
        .get(this.$store.getters.getTicket(), {
          params: {
            search: this.search,
            page: this.page,
            size: this.perPage,
            status: this.status
          }
        })
        .then(response => {
          this.lists = response.results.map( ticket => {
            return {
              ...ticket
            }
          })
          let tickets = {}
          this.lists.forEach(ticket => {
            tickets[ticket.id] = ticket.fk_operator
          })
          this.operators = tickets
          this.total = response.count
        })
        .finally(() => {
          this.loading = false;
        })
    },
    getOperators() {
      this.axios
        .get(this.$store.getters.getOperators())
        .then(response => {
          this.users = response.results
        })
        .finally(() => {
          this.loading = false;
        })
    },
    updateOperator(id) {
      if(this.operators[id]){
        const data = {
          fk_operator: this.operators[id],
          status: 'assigned'
        }
        this.axios
          .put(this.$store.getters.getTicket(id),data)
          .then(() => {
            this.ticketList()
            this.$awn.success(this.$t('messages.edited_successfully'))
          })
          .finally(() => {
            this.loading = false;
          })
      }
    },
    getColor(status) {
      return getColor(status)
    }
  },
  mounted() {
    this.ticketList();
    this.getOperators();
  }
};
